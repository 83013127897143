var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("SelectStore", {
        attrs: { rStore: _vm.rStore },
        on: { selectStore: _vm.handleSelectStore },
      }),
      _c("div", { staticClass: "right-container" }, [
        _c(
          "div",
          { staticClass: "list-container", style: { height: _vm.height } },
          [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "text" }, [_vm._v("门店运单记录")]),
              _c(
                "div",
                { staticClass: "return" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.goBack } },
                    [_c("span", { staticClass: "returnBtn" }, [_vm._v("返回")])]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "toolbar" },
              [
                _c(
                  "el-input",
                  {
                    style: {
                      width: "250px",
                      marginTop: "15px",
                      float: "right",
                    },
                    attrs: { placeholder: "查询关键字", clearable: true },
                    model: {
                      value: _vm.keyword,
                      callback: function ($$v) {
                        _vm.keyword = $$v
                      },
                      expression: "keyword",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: { click: _vm.search },
                      slot: "append",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "table",
                attrs: {
                  data: _vm.ordFreightList,
                  "highlight-current-row": "",
                  "max-height": _vm.tableMaxHeight,
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "序号",
                    type: "index",
                    align: "center",
                    width: "60px",
                    index: _vm.getIndex,
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "门店名称",
                    align: "center",
                    width: "140px",
                    prop: "sStore",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "配送单号",
                    align: "center",
                    width: "135px",
                    prop: "cSys",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "配送平台",
                    align: "center",
                    width: "100px",
                    prop: "sPlatform",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "距离",
                    align: "center",
                    width: "50px",
                    prop: "nDistance",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "用时",
                    align: "center",
                    width: "70px",
                    prop: "fDelivery",
                    formatter: _vm.timeFormatter,
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "超时",
                    align: "center",
                    width: "50px",
                    prop: "nDistance",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getTimeout(scope.row.bTimeout)) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "运费",
                    align: "center",
                    width: "60px",
                    prop: "nDelivery",
                    formatter: _vm.priceFormatter,
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "小费",
                    align: "center",
                    width: "60px",
                    prop: "nDispatch",
                    formatter: _vm.priceFormatter,
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "小计",
                    align: "center",
                    width: "60px",
                    prop: "nTotal",
                    formatter: _vm.priceFormatter,
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "完成时间",
                    align: "center",
                    width: "140px",
                    prop: "tComplete",
                    formatter: _vm.dateFormatter,
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "下单时间",
                    align: "center",
                    width: "140px",
                    prop: "tCreate",
                    formatter: _vm.dateFormatter,
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "接单时间",
                    align: "center",
                    width: "140px",
                    prop: "tReceive",
                    formatter: _vm.dateFormatter,
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "接单时间",
                    align: "center",
                    width: "140px",
                    prop: "tArrive",
                    formatter: _vm.dateFormatter,
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    fixed: "right",
                    align: "center",
                    label: "操作",
                    width: "70px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.view(scope.row)
                                },
                              },
                            },
                            [_vm._v("详情")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("el-pagination", {
              style: { marginTop: "15px" },
              attrs: {
                background: "",
                layout: _vm.pageLayout,
                total: _vm.total,
                "current-page": _vm.pageNum,
                "page-sizes": _vm.pageSelect,
                "page-size": _vm.pageSize,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
            _c("ViewOrdFreightDetail", {
              attrs: {
                visible: _vm.showViewOrdFreightDetail,
                sid: _vm.currentSid,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showViewOrdFreightDetail = $event
                },
                close: _vm.handleCloseViewOrdFreightDetail,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }