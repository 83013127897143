<template>
  <div class="content">
    <div class="container" v-show="!showStoreOrderDetail" v-loading="showStoreOrderDetail">
      <SelectStore @selectStore="handleSelectStore"/>
      <div class="right-container">
        <div class="list-container" :style="{height: height}">
          <div class="header">门店运效记录</div>
          <div class="toolbar">
            <el-input placeholder="查询关键字" v-model="keyword" :clearable="true" :style="{ width:'250px',marginTop: '15px',float: 'right'}">
              <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
            </el-input>
          </div>
          <el-table :data="ordFreightAnalyseStoreList" highlight-current-row ref="table" v-loading="loading">
            <el-table-column label="序号" type="index" align="center" width="60px" :index="getIndex"></el-table-column>
            <el-table-column label="门店名称" align="center" width="130px" prop="sStore"></el-table-column>
            <el-table-column label="订单量" align="center" width="60px" prop="totalReceive"></el-table-column>
            <el-table-column label="配送量" align="center" width="60px" prop="totalDelivery"></el-table-column>
            <el-table-column label="运费合计" align="center" width="90px" prop="totalDeliveryCost" :formatter="priceFormatter"></el-table-column>
            <el-table-column label="小费合计" align="center" width="90px" prop="totalDispatchCost" :formatter="priceFormatter"></el-table-column>
            <el-table-column label="运费总计" align="center" width="90px" prop="totalCost" :formatter="priceFormatter"></el-table-column>
            <el-table-column label="平均时长" align="center" width="90px" prop="avgDeliveryTime" :formatter="timeFormatter"></el-table-column>
            <el-table-column label="平均运费" align="center" width="90px" prop="avgDeliveryCost" :formatter="priceFormatter"></el-table-column>
            <el-table-column fixed="right" align="center" label="操作" width="60px">
              <template slot-scope="scope">
                <el-button type="text" @click="view(scope.row)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            :layout="pageLayout"
            :total="total"
            :current-page="pageNum"
            :page-sizes="pageSelect"
            :page-size="pageSize"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :style="{marginTop: '15px'}">
          </el-pagination>
        </div>
      </div>
    </div>
    <StoreOrderDetail v-if="showStoreOrderDetail" @close="handleCloseDetail" :rStore="rStore"/>
  </div>
</template>

<script>
import {getOrdFreightAnalyseStoreList} from "@/api/delivery";
import {freightTypeMap,freightStatusMap} from "@/utils/deliveryMap";
import {formatTime,formatSeconds} from "@/utils/utils";
import SelectStore from "@/views/components/SelectStore";
import StoreOrderDetail from "@/views/components/DeliveryEffiRider/StoreOrderDetail";

export default {
  name: "deliveryEffiStore",
  components: {SelectStore,StoreOrderDetail},
  data() {
    return {
      height: document.documentElement.clientHeight - 60 + "px",
      rStores:[],
      keyword:'',
      ordFreightAnalyseStoreList:[],
      loading:false,
      total: 0, //总条数
      pageNum: 1, //当前页
      pageSize: 10, //每页显示条数
      pageSelect: [10, 20, 30, 50],
      pageLayout:"prev, pager, next,jumper,total,sizes",
      showStoreOrderDetail:false,
    };
  },
  created() {
    //this.getOrdFreightList();
  },
  mounted() {

  },
  computed: {
    getFreightType() {
      return function(bType) {
        return freightTypeMap[bType] || '未知';
      };
    },
    getFreightStatus() {
      return function(bType) {
        return freightStatusMap[bType] || '未知';
      };
    },
  },
  methods: {
    getOrdFreightAnalyseStoreList() {
      let params = {
        search:this.keyword,
        rStores:this.rStores,
        pageNum:this.pageNum,
        pageSize:this.pageSize,
      }
      this.loading = true;
      getOrdFreightAnalyseStoreList(params).then(result => {
        if (result) {
          this.ordFreightAnalyseStoreList = result.rows;
          this.total = result.total;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    // 查询
    search(){
      this.pageNum = 1;
      this.getOrdFreightAnalyseStoreList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getOrdFreightAnalyseStoreList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getOrdFreightAnalyseStoreList();
    },
    handleSelectStore(e) {
      this.rStores = e;
      this.pageNum = 1;
      this.getOrdFreightAnalyseStoreList();
    },
    handleCloseDetail(){
      this.showStoreOrderDetail = false;
    },
    view(item){
      this.rStore = item.rStore;
      this.showStoreOrderDetail = true;
    },
    getIndex(rowIndex) {
      return (this.pageNum - 1) * this.pageSize + rowIndex +1;
    },
    priceFormatter(row, column, cellValue) {
      return cellValue?'￥'+cellValue:'';
    },
    timeFormatter(row, column, cellValue) {
      return formatSeconds(cellValue);
    },
    dateFormatter(row, column, cellValue) {
      return cellValue?formatTime(cellValue,"YYYY-MM-DD HH:mm"):'';
    },
  },
};
</script>

<style lang="scss" scoped>
.content{
  width: 100%;
  height: 100%;
}
.container{
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
}
.right-container{
  float:left;
  width:calc(100% - 240px);
  height: 100%;
}
.list-container{
  width: 100%;
  border: 1px solid #ddd;
  padding: 0px 20px;
  .header{
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    padding-left: 5px;
    border-bottom: 1px solid #ddd;
  }
  .toolbar{
    width: 100%;
    height: 60px;
  }
}
</style>
