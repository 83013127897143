var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showStoreOrderDetail,
              expression: "!showStoreOrderDetail",
            },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.showStoreOrderDetail,
              expression: "showStoreOrderDetail",
            },
          ],
          staticClass: "container",
        },
        [
          _c("SelectStore", { on: { selectStore: _vm.handleSelectStore } }),
          _c("div", { staticClass: "right-container" }, [
            _c(
              "div",
              { staticClass: "list-container", style: { height: _vm.height } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("门店运效记录")]),
                _c(
                  "div",
                  { staticClass: "toolbar" },
                  [
                    _c(
                      "el-input",
                      {
                        style: {
                          width: "250px",
                          marginTop: "15px",
                          float: "right",
                        },
                        attrs: { placeholder: "查询关键字", clearable: true },
                        model: {
                          value: _vm.keyword,
                          callback: function ($$v) {
                            _vm.keyword = $$v
                          },
                          expression: "keyword",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: { slot: "append", icon: "el-icon-search" },
                          on: { click: _vm.search },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "table",
                    attrs: {
                      data: _vm.ordFreightAnalyseStoreList,
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        type: "index",
                        align: "center",
                        width: "60px",
                        index: _vm.getIndex,
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "门店名称",
                        align: "center",
                        width: "130px",
                        prop: "sStore",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "订单量",
                        align: "center",
                        width: "60px",
                        prop: "totalReceive",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "配送量",
                        align: "center",
                        width: "60px",
                        prop: "totalDelivery",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "运费合计",
                        align: "center",
                        width: "90px",
                        prop: "totalDeliveryCost",
                        formatter: _vm.priceFormatter,
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "小费合计",
                        align: "center",
                        width: "90px",
                        prop: "totalDispatchCost",
                        formatter: _vm.priceFormatter,
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "运费总计",
                        align: "center",
                        width: "90px",
                        prop: "totalCost",
                        formatter: _vm.priceFormatter,
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "平均时长",
                        align: "center",
                        width: "90px",
                        prop: "avgDeliveryTime",
                        formatter: _vm.timeFormatter,
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "平均运费",
                        align: "center",
                        width: "90px",
                        prop: "avgDeliveryCost",
                        formatter: _vm.priceFormatter,
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        fixed: "right",
                        align: "center",
                        label: "操作",
                        width: "60px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.view(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("详情")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("el-pagination", {
                  style: { marginTop: "15px" },
                  attrs: {
                    background: "",
                    layout: _vm.pageLayout,
                    total: _vm.total,
                    "current-page": _vm.pageNum,
                    "page-sizes": _vm.pageSelect,
                    "page-size": _vm.pageSize,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.showStoreOrderDetail
        ? _c("StoreOrderDetail", {
            attrs: { rStore: _vm.rStore },
            on: { close: _vm.handleCloseDetail },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }